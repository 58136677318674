<template>
    <div id="welcome" style="height:100%;">
        <!-- <p class="tipsTop" @click="confirmFullScreen"> -->
            <!-- {{isFullScreen ? '请答题！' : '请点击F11，开启全屏模式，然后开始答题！'}} -->
            <!-- <span v-if="isFullScreen">请答题！</span>
            <el-button v-else @click="Fullscreen">点击进入全屏</el-button> -->
        <!-- </p> -->
    <div class="wrap" >
        <!-- 在线考试答题 -->
        
        <div class="kaoshi" v-if="KSPageShow && isOKCamera">
            <!-- <p class="title">在线考试</p> -->
            <p class="tips">答题过程中，请勿刷新或离开本页面</p>
            <p class="examinIndex" v-show="answerComponentIsshow">本次考试共<b>{{ loadingExaminInfo.length }}</b>套试卷，当前是第<b>{{ examinIndex + 1 }}</b>套</p>
            <div class="answerWrap" >
                <p class="curTop">
                    <span class="curItem" :class="[typeStyle, 'curItem']">[{{typeTetxt}}]
                        {{ index+ 1}} / {{questionList.length }}</span>
                        <span>
                            剩余<span class="time">{{rocallTime}}</span>分钟

                            <el-popover
                                placement="bottom"
                                width="420"
                                trigger="click">
                                <div class="answerInfo">
                                    <div class="total-questions">共 {{ questionList.length }} 题</div>
                                    <el-row :gutter="20">
                                        <el-col :span="3" v-for="(question, index) in  questionList" :key="index">
                                            <el-button @click="scrollToQuestion(index)" size="mini" :type="getButtonStyle(index,question)"
                                                style="margin-bottom: 10px;">
                                                {{ index + 1 }}
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                                <el-button type="primary" slot="reference">答题卡</el-button>
                                </el-popover>
                        </span>
                </p>
                <div class="mainCon" >
                    <answerList :isFullScreen="!isFullScreen" :isFinisheed="isFinished" :question="questionList[index]"  :index="index" :selectedOptions="selectedOptions"  style="flex: 1;""></answerList>
                    
                </div>

            
            </div>
            <div class="bottomLine">
                <span><el-button type="text" @click="onPre" :disabled="!isFullScreen || isFinished"><i class="el-icon-d-arrow-left"></i>上一题</el-button></span>
                <span><el-button type="text" @click="nextQustion" :disabled="!isFullScreen || isFinished"><i class="el-icon-d-arrow-right"></i>下一题</el-button></span>
                <span><el-button type="primary" @click="dialogJiaojuan = true" :disabled="!isFullScreen || isFinished"><i class="el-icon-alarm-clock"></i>交卷</el-button></span>
            </div>
        </div>
        <div v-else style="flex:1;height:50px;line-height:50px;color:red;text-align: center;">
            {{ KSPageErrContent }}
        </div> 
        
    </div> 
    <div class="camraWrap" :class="camraWrapClass.switchClass">
            <!--开启摄像头-->
                <!-- <img @click="callCamera" :src="headImgSrc" alt="摄像头"> -->

                <!--canvas截取流-->
                <div v-if="!isOKCamera" style="text-align: center;margin-top:20px;color: red;">
                    摄像头开启失败!
                    <p style="margin-top:20px;">请检查摄像头是否可用！</p>    
                </div>
                <div v-else>
                    <canvas ref="canvas" width="320" height="240" style="display: none;"></canvas>
                    <!--图片展示-->
                    <video ref="carmerVideo" width="320" height="240" autoplay ></video>
                </div>
                <!--确认-->
                <!-- <el-button size="mini" type="primary" @click="photograph"></el-button> -->
                <!-- <el-button size="mini" type="primary" @click="closeCamera">关闭摄像头</el-button> -->
        </div>
        
        <foot></foot>
        <el-dialog
            title="考试结果"
            :visible.sync="dialogVisible"
            :show-close="false"
            :center="true"
            :close-on-click-modal="false"
            width="50%"
            >
            <div>
                <el-result v-if="isPass == 1" icon="success" title="考试通过" >
                </el-result>
                <el-result v-if="isPass == 0" icon="error" title="考试未通过" >
                </el-result>
                <div style="padding-left:200px;">
                    <p style="line-height:40px;height:40px;font-size:18px;">学员姓名：{{ userInfo.personName }}</p>
                    <p style="line-height:40px;height:40px;font-size:18px;">测试得分：{{ scroce }}分</p>
                    <p style="line-height:40px;height:40px;font-size:18px;">测试开始时间：{{beginTime}}</p>
                    <p style="line-height:40px;height:40px;font-size:18px;">测试结束时间：{{endTime}}</p>
                </div>
                <div style="text-align: center;">
                    <el-button  type="primary" @click="reStart" v-if="examinIndex == loadingExaminInfo.length -1 && remainCount > 0">还有{{ remainCount }}次机会，点击重新测试</el-button>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false;nextExamin()" v-if="examinIndex < loadingExaminInfo.length -1">点击开启第{{ examinIndex + 2 }} 套试卷，开始答题</el-button>
                <!-- 点击确定后跳转至考试记录列表页面 -->
                <el-button type="primary" @click="dialogVisible = false;nextExamin();isFinished = true" v-else>确 定</el-button> 
            </span>
        </el-dialog>
        <el-dialog
            title="温馨提示"
            :visible.sync="startdialogVisible"
            width="50%"
            :show-close="false"
            :center="true"
            :close-on-click-modal="false"
            >
            <ul style="margin:40px 30px;">
                <!-- <li class="tipsItem" style="">
                    1.请点击F11，开启全屏模式，然后开始答题！
                </li> -->
                <li class="tipsItem">
                    1.答题过程中，请勿刷新或离开本页面。
                </li>
                <li class="tipsItem">
                    2.如离开本页面5次将触发自动交卷。
                </li>
            </ul>
            <span></span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="startdialogVisible = false;start()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="dialogAlter"
            width="50%"
            :close-on-click-modal="false"
            >
            <!-- :before-close="handleClose" -->
            <span style="color: red;font-size:30px;font-weight: bold;">由于您离开次数过多，将强制交卷,交卷中。。。</span>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <!-- <el-button type="primary" @click="dialogAlter = false;examineEnd()">我要交卷</el-button> -->
            </span>
            </el-dialog>
            <!-- 交卷提示框 -->
        <el-dialog
            title="提示"
            :visible.sync="dialogJiaojuan"
            width="50%"
            :center="true"
            :close-on-click-modal="false"
            >
            <p style="color: red;font-size:30px;font-weight: bold;line-height:40px;">确定要交卷吗？</p>
            <p style="color: red;font-size:30px;font-weight: bold;line-height:40px;">提示：(请检查全部试题是否已做完)</p>
            <span slot="footer" class="dialog-footer">

                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="dialogJiaojuan = false;submitjuanquan()">确认交卷</el-button>
            </span>
        </el-dialog>
    </div>
</template> 
<script>
import netTool from '../../api/netTool';
import answerList from '../../components/answerList.vue'
import foot  from '../../components/footer.vue';
import { Loading } from 'element-ui'

import OOS from '../../utils/oos-sdk-6.0.min'
export default {
    
    data() {
        return {
            canvasElement:'',
            videoElement:'',
            dialogAlter:false,
            KSPageShow:null,
            KSPageErrContent:'',
            personName:'',
            userInfo:{},
            isLoading:false,
            startdialogVisible:false,
            dialogVisible:false,
            scroce:70,
            isPass:0,
            beginTime:'',
            endTime:'',
            isFullScreen:true,
            isFinished:false,
            rocallTime:null,
            isOKCamera:true,
            CameraisStart:null,
            headImgSrc: require('@/assets/images/camera.png'),
            camraWrapClass:{
                switchClass:'close'
            },
            num: 1,
            flag: true,
            fullClass:{
                switchClass:'el-icon-full-screen ',
            },
            index: 0,
            questions: [
                {
                  title: '多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例多选题示例',
                  type: 'multiple',
                  options: ['选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A选项A', '选项B', '选项C'],
                  correctAnswer: [0, 2],
               },
               {
                  title: '单选题示例',
                  type: 'single',
                  options: ['选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1选项1', '选项2', '选项3'],
                  correctAnswer: 0,
               },
               
               {
                  title: '判断题示例',
                  type: 'trueFalse',
                  options: [],
                  correctAnswer: 'true',
               },
               {
                  title: '单选题示例',
                  type: 'single',
                  options: ['选项1', '选项2', '选项3'],
                  correctAnswer: 0,
               },
               {
                  title: '多选题示例',
                  type: 'multiple',
                  options: ['选项A', '选项B', '选项C'],
                  correctAnswer: [0, 2],
               },
               {
                  title: '判断题示例',
                  type: 'trueFalse',
                  options: [],
                  correctAnswer: 'true',
               },
            ],
            i:0,
            answerList:{
                radio1:'',
                radio2:'',
                radio3:'',
                radio4:'',
            },
            selectedOptions: [],
            video: null,
            MediaStreamTrack: null,
            bokId :'',
            subjectId :'',
            learningId:'',
            questionList:[],// 题目列表集合
            examTime:'',// 考试时长 
            examCount:'', // 考试次数
            id:'', // 试卷id
            passMark:'', // 通过分数
            remainCount:'', // 剩余考试次数
            answerComponentIsshow:false,
            examinId:'',// 考试id
            loadingExaminInfo:[], // 加载试卷返回的数据集合
            ExaminInfo:{},// 当前试卷信息
            examinIndex:0, // 第几套试卷
            batchId:'', // 考试批次
            countDownTimer:undefined,  // 定时器
            num:0, // 离开当前页面的次数
            endTime:0,
            timer:null,
            count:0,
            endTimeAnwswer:0,
            isCurrent:true,
            fuwuTimer:null,
            currentTime:null,
            endTimeAnwswer:null,
            dialogJiaojuan:false,
            isStart:false,
            isEnd:0,
            currentPageTimer11:null
        }
    },
    components:{
        answerList,
        foot
    },
    mounted() {
        this.userInfo = this.$store.state.userInfo;
        
        this.video = this.$refs.videoSour;
        let that = this;
        
        // window.addEventListener("keydown", function (e) {
        //     if (e && e.keyCode == 122) {
        //         console.log('p0p0p0p0')
        //         //捕捉F11键盘动作
        //         e.preventDefault(); //阻止F11默认动作
        //         // 自定义全屏事件
        //         that.handleFullScreen();
        //         // that.checkFull()
        //         // console.log('checkFull',that.checkFull())
        //         // that.isFullScreen = true;
        //     }
        // });
 
        // window.addEventListener("resize", () => {
        //     // console.log(window.outerHeight,window.innerHeight,screen.height , screen.availHeight)
        //     if(window.innerHeight >= window.outerHeight){
        //         that.isFullScreen = true
        //         // console.log('kkkkk')
        //         // that.videoClassForm.switchClass = 'el-icon-video-pause switch';
        //         that.camraWrapClass.switchClass = 'open' // 开启摄像头面板
        //         that.isFinished  == false ? that.callCamera() : ''
        //     }else {
        //         that.isFullScreen = false
        //     }
        // });
        // document.addEventListener('fullscreenchange', that.checkFullScreen);
        // if(this.KSPageShow){
            that.isCurrentPage()
        // }

        this.videoElement = this.$refs.carmerVideo;
        this.canvasElement = this.$refs.canvas;

        // this.startCountdown();
        // this.isinCurrentPage()
     

    },
    watch:{
        isCurrent:{
            handler(newVal, oldVal){
                // console.log(newVal, oldVal)
                if(newVal == true){
                    // 重新计算倒计时
                    if(this.isStart == true){ // 考试开始后才能校验时间
                        this.getFuwuTime()
                        let mins = this.minutesUntil(this.currentTime, this.endTimeAnwswer)
                        // console.log('mins',mins)
                        this.count = mins*60
                        console.log('watch' ,this.count)
                        if(this.countDownTimer){
                            clearInterval(this.countDownTimer);
                            // console.log('考试期间倒计时定时器以清除');
                        }
                        this.countDown()
                    }
                    
                }
            },
            deep:true,
            immediate:false
        }
  },
    updated() {
        this.video = this.$refs.videoSour;
    },
    async created() {
        this.personName = localStorage.getItem('uuid')
        // this.selectedOptions = Array.from({ length: this.questionList.length }, () => []);
        this.bokId = this.$route.query.bokId;
        // this.subjectId = this.$route.query.subjectId;
        this.learningId = this.$route.query.learningId;
        this.loadingexamine()
        await this.getCtyunSTS();
    },
    computed:{
        typeTetxt(){
            if(this.questionList.length > 0){
                return this.questionList[this.index].type == 2 ? '多选题' : this.questionList[this.index].type == 3 ? '判断题' : this.questionList[this.index].type == 4 ? '简答' : '单选题'
            }
        },
        typeStyle(){
            if(this.questionList.length > 0){
                return this.questionList[this.index].type == 2 ? 'typeStyle2' : this.questionList[this.index].type == 3 ? 'typeStyle3' : this.questionList[this.index].type == 4 ? 'typeStyle4' : 'typeStyle1'
            }
        },
    },
    methods: {
        async getCtyunSTS() {
            try {
                const res = await netTool.getCtyun({bucket: 'confidential-information'});
                if (res.status === 0) {
                this.ossSts = res.data;
                if (res.data) {
                    this.s3 = new window.OOS.S3({
                    accessKeyId: res.data.accessKeyId,
                    secretAccessKey: res.data.accessKeySecret,
                    endpoint: 'https://oos-cn.ctyunapi.cn',
                    signatureVersion: 'v2',
                    apiVersion: '2006-03-01',
                    s3ForcePathStyle: true,
                    sessionToken: res.data.stsToken
                    });
                    this.stsReady = true; // 标志STS已准备就绪
                }
                } else {
                this.$notify.error({
                    title: '错误',
                    message: res.msg
                });
                // this.$store.goLoginPage();
                }
            } catch (error) {
                this.$notify.error({
                title: '错误',
                message: error.message
                });
                // this.$store.goLoginPage();
            }
        },
        isinCurrentPage(){
            let that = this;
            window.onfocus = function() { 
                document.title = "正在考试中,请勿离开"; 
                that.isCurrent = true;
            }
            window.onblur = function() { 
                document.title = "请继续考试"; 
                that.isCurrent = false;
                
                // console.log(num) 
            } 
        },
        //判断焦点是否在当前页面，防切屏
        isCurrentPage(){
            // let num = 0 ;
            let _this = this 
            window.onblur = function() { 
                document.title = "请继续考试"; 
                _this.num++ 
                _this.isCurrent = false;
                // if(_this.countDownTimer){
                //     clearInterval(_this.countDownTimer);
                //     console.log('考试期间倒计时定时器以清除');
                // }
                // console.log(num) 
            } 
            window.onfocus = function() { 
                document.title = "正在考试中,请勿离开"; 
                _this.isCurrent = true;
                if (_this.num === 1 || _this.num === 2 || _this.num === 3 || _this.num === 4) { 
                    // _this.$message.info("已离开当前页面" + _this.num + "次，离开5次将强制交卷") 
                    _this.$message({
                        message: "已离开当前页面" + _this.num + "次，离开5次将强制交卷",
                        // type: 'error',
                        iconClass:'iconNew',
                        customClass:'new_message',
                        duration:5000,
                        offset:200
                    });
                } 
                if (_this.num > 4) { 
                    // _this.exitScreen() 
                    //退出全屏 
                    
                    _this.dialogAlter = true;

                    // if(currentPageTimer11)return
                    if(_this.currentPageTimer11 == null){
                        _this.currentPageTimer11 = setTimeout(() => {
                            _this.dialogAlter = false;
                            // clearTimeout(currentPageTimer11);
                            // console.log('---5555')
                            _this.examineEnd() 
                            // _this.$router.push('/record')
                        }, 10000);
                    }
                    
                    // _this.$alert('由于您离开次数过多，将强制交卷', '提示', {
                    //     showClose: false, confirmButtonText: '我要交卷', callback: action => { 
                    //         
                    //         // _this.$message.success("交卷成功！") 
                    //     } 
                    //     }); 
                } 
            }
        },
        handleClose(){
            // this.dialogAlter = false;
            // console.log('---')
            // this.examineEnd()
        },
        //清除当前页面防切屏的限制
        clearCurrentPage(){
            window.onfocus = null
            window.onblur = null
            document.title = "专家培训考试系统"; 
        },
        //重新开始考试
        reStart(){
            this.examinIndex = 0;
            this.dialogVisible = false;
            this.loadingexamine()
        },
        // 加载试题
        loadingexamine(){
            let params = {
                // id: 0,
                platformId: this.$store.state.pfId,
                expertUuid: localStorage.getItem('expertUuid'),
                batchId:this.batchId,
                bokId: this.bokId,
                category:localStorage.getItem('userType') == '3' ? 1: 2
                // subjectId: this.subjectId,
                // learningId: this.learningId,
                // paperId: 0
            }
            // 声明请求拦截器
            let loadingInstance = null
            loadingInstance = Loading.service({ fullscreen: true})
            netTool.examineLoad(params).then(res => {
                loadingInstance.close()
                if(res.data != null){
                    
                    this.loadingExaminInfo = res.data; //试卷集合
                    if(this.loadingExaminInfo.length > 0){
                        console.log('131232',this.isOKCamera)
                        //获取第一套试卷
                        this.getExamin(0)
                        this.selectedOptions = Array.from({ length: this.questionList.length }, () => []);
                            this.answerCompoenntIsshow = true;
                            this.KSPageShow = true;
                            this.startdialogVisible = true;
                    }
                }else{
                    // this.$message.error(res.msg);
                    this.KSPageShow = false;
                    this.KSPageErrContent = res.msg
                    window.onfocus = null;
                    window.onblur = null;
                }
            })
        },
        // 获取当前试卷数据
        getExamin(index){
            return new Promise((resolve, reject) =>{ 
                this.batchId = this.loadingExaminInfo[index].batchId; // 考试批次
                this.examinId = this.loadingExaminInfo[index].id; // 考试id
                this.ExaminInfo = this.loadingExaminInfo[index]
                this.examTime = this.loadingExaminInfo[index].examPaper.examTime // 考试时长
                this.questionList = this.loadingExaminInfo[index].examPaper.questionList
                this.examTime = this.loadingExaminInfo[index].examPaper.examTime
                // this.examTime = 2

                this.examCount = this.loadingExaminInfo[index].examPaper.examCount
                this.id = this.loadingExaminInfo[index].examPaper.id // 试卷id
                this.passMark = this.loadingExaminInfo[index].examPaper.passMark
                resolve(true)
            }).catch(err =>{
                    reject()        
                }) 
            
        },
        //开始答题
        async start(){
            this.startdialogVisible = false; // 关闭弹框
            this.camraWrapClass.switchClass = 'open'
           
            let result = await this.callCamera()
            // let result = this.isOKCamera
            this.takeSnapshot3()  // 拍照上传
            // let result = true
            console.log('result',result)
            if(result){ // 等待摄像头开启成功后，再开始答题
               
                this.index =0 ;
                this.selectedOptions = Array.from({ length: this.questionList.length }, () => []);
                // 开始定时器
                // console.log('this.examTime',this.examTime)
                this.count = 60*this.examTime
                // console.log('this.count',this.count)
                this.countDown(this.count)
                let params = {
                    id: this.examinId,
                    platformId: this.ExaminInfo.platformId,
                    expertUuid: this.ExaminInfo.expertUuid,
                    bokId: this.ExaminInfo.bokId,
                    subjectId: this.ExaminInfo.subjectId,
                    learningId: this.ExaminInfo.learningId,
                    paperId: this.id,
                    batchId:this.batchId
                }
                netTool.startExamin(params).then(res => {
                    
                    // let beginTime = new Date(res.data.beginTime)

                    // this.endTimeAnwswer = new Date(beginTime.getTime() + this.examTime * 60000);
                    // localStorage.setItem('endTimeAnwswer', this.endTimeAnwswer)
                    // this.getFuwuTime()
                    if(res.status == 0){
                        this.isStart = true;
                        this.photograph() // 考试开始时拍照
                        this.fuwuTime5M()
                    }else{
                        this.$notify.error({
                            title: '错误',
                            message: res.msg
                        });
                    }

                   
                })
            }
            
        },
        ///专家培训 - 保存答题，点击下一题时调用
        saveAnswer(id,anwserIdList,content){
            let params={
                platformId: this.ExaminInfo.platformId,
                expertUuid: this.ExaminInfo.expertUuid,
                bokId: this.ExaminInfo.bokId,
                subjectId: this.ExaminInfo.subjectId,
                learningId: this.ExaminInfo.learningId,
                batchId:this.ExaminInfo.batchId,
                paperId: this.id,
                examinationId: this.examinId, //考试记录id
                answerList: [
                    {
                        questionId: id,
                        optionIdList: anwserIdList,
                        content:content
                    }
                ],
                isEnd:this.isEnd,
            }
            netTool.examineSave(params).then(res => {
                if(res.status !== 0){
                    this.$notify.error({
                        title: '错误',
                        message: res.msg
                    });
                }else{
                    
                }
            })
        },
        saveAllAnwser(){
            let answerList = Array.from({ length: this.questionList.length }, () => {return {
                optionIdList:[],
                questionId:'',
                content:''
            }});
            
            for(let i = 0; i< this.selectedOptions.length; i++){
                if(this.questionList[i].type !== 4){
                    answerList[i].optionIdList = this.selectedOptions[i]
                }
                
            }
            for(let i = 0; i< this.questionList.length; i++){
                if(this.questionList[i].type == 4){
                    answerList[i].content = this.selectedOptions[i][0]
                }
                answerList[i].questionId = this.questionList[i].id
            }
            let params={
                platformId: this.ExaminInfo.platformId,
                expertUuid: this.ExaminInfo.expertUuid,
                bokId: this.ExaminInfo.bokId,
                subjectId: this.ExaminInfo.subjectId,
                // learningId: this.ExaminInfo.learningId,
                paperId: this.id,
                // examinationId: this.examinId, //考试记录id
                answerList: answerList,
                batchId:this.batchId,
                isEnd:this.isEnd,
            }
            return new Promise((resolve, reject) =>{ 
                netTool.examineSave(params).then(res => {
                    if(res.status == 0){
                        this.$message.success("交卷成功！") 
                        resolve(res)
                    }else{
                        this.$notify.error({
                            title: '错误',
                            message: res.msg
                        });
                    }
                }).catch(err =>{
                    reject(err.data)        
                })    
            })
        },
        // 专家培训 - 结束答题,,交卷
        async examineEnd(){
            try {
                let allSave = await this.saveAllAnwser()
                this.isEnd = 1;
                this.photograph()  // 交卷时拍照一次
                clearTimeout(this.currentPageTimer11)
                if(allSave.status == 0){
                    let params={
                        id: this.examinId, // 考试id
                        platformId: this.ExaminInfo.platformId,
                        expertUuid: this.ExaminInfo.expertUuid,
                        bokId: this.ExaminInfo.bokId,
                        subjectId: this.ExaminInfo.subjectId,
                        // learningId: this.ExaminInfo.learningId,
                        paperId: this.id,
                        batchId:this.ExaminInfo.batchId,
                    }
                    netTool.examineEnd(params).then(res => {
                        if(res.status == 0){
                            this.scroce = res.data.score;
                            this.remainCount = res.data.remainCount;
                            this.isPass = res.data.isPass;
                            this.beginTime = res.data.beginTime;
                            this.endTime = res.data.endTime;
                            // this.dialogVisible = true;
                            
                            clearInterval(this.countDownTimer); 
                            clearInterval(this.timer); // 清除拍照定时器
                            clearInterval(this.fuwuTimer);
                            this.toRecord()
                            // this.isFinished = true;
                            
                            
                        }else{
                            this.$notify.error({
                                title: '错误',
                                message: res.msg
                            });
                        }
                    })
                }
            }catch(err) {
                // console.log(err);
            }
        },

        // 如果有多套试题，答完本套试题是否开启下套试题
       async nextExamin(){
            if(this.examinIndex < this.loadingExaminInfo.length -1 ){
                this.examinIndex += 1;
                // console.log(this.examinIndex)
                let result = await this.getExamin(this.examinIndex)
                if(result){
                    clearInterval(this.countDownTimer);
                    this.start()
                }
               
            }else{
                // 关闭摄像头
                this.$router.push('/examinRecord')
                this.closeCamera()
                this.clearCurrentPage()
            }
        },
        toRecord(){
            // 关闭摄像头
            this.$router.push('/examinRecord')
            this.closeCamera()
            this.clearCurrentPage()
        },

        handleFullscreenChange() {
            if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
                // console.log('进入全屏模式');
            } else {
                // console.log('退出全屏模式');
            }
        },
        handleFullScreen() {
            // 全屏切换
            // console.log('每次都调用吗？')
            
            if (this.isFullScreen) {
                this.isFullScreen = false
                // 全屏
                var fullscreen = document.getElementById("welcome"); // 需要全屏的元素
                if (fullscreen.exitFullscreen) {
                    fullscreen.exitFullscreen();
                } else if (fullscreen.msExitFullscreen) {
                    fullscreen.msExitFullscreen();
                } else if (fullscreen.mozCancelFullScreen) {
                    fullscreen.mozCancelFullScreen();
                } else if (fullscreen.webkitCancelFullScreen) {
                    fullscreen.webkitCancelFullScreen();
                }
                
            } else {
                this.isFullScreen = true
                // 非全屏
                this.Fullscreen()
                
            }
            // console.log("是否全屏", this.isFullScreen);
        },
        Fullscreen(){
            var fullscreen = document.getElementById("welcome"); // 需要全屏的元素
                if (fullscreen.requestFullscreen) {
                    fullscreen.requestFullscreen();
                } else if (fullscreen.mozRequestFullScreen) {
                    fullscreen.mozRequestFullScreen();
                } else if (fullscreen.webkitRequestFullscreen) {
                    fullscreen.webkitRequestFullscreen();
                } else if (fullscreen.msRequestFullscreen) {
                    fullscreen.msRequestFullscreen();
                }
        },
        // 点击进入全屏模式
        confirmFullScreen(){
            this.isFullScreen = false;
        },
        // F11
        KeyDown(event) {
            setTimeout(() => {
                // console.log('0000000')
                // this.windowHeight = document.documentElement.clientHeight + 'px'
            },1000);
        },

        checkFull () {
            // console.log('=====')
            let isFull =
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            if (isFull === undefined) isFull = false
            return isFull
        },
        // 调用摄像头
        callCamera() {
            console.log('000000')
            return new Promise((resolve, reject) =>{ 
            // H5调用电脑摄像头API
                navigator.mediaDevices.getUserMedia({
                    video: true
                }).then(success => {
                    this.isOKCamera = true;
                    // 摄像头开启成功
                    this.$refs['carmerVideo'].srcObject = success
                    // 实时拍照效果
                    this.$refs['carmerVideo'].style.display = 'block'
                    this.$refs['carmerVideo'].play()
                    //this.takeSnapshot3()  // 拍照上传
                    resolve(true)
                }).catch(error => {
                    this.$message.error('摄像头开启失败，请检查摄像头是否可用！');
                    this.isOKCamera = false;
                    this.KSPageErrContent = '摄像头开始失败，暂不能参加考试！'
                    window.onfocus = null;
                    window.onblur = null;
                    // console.error('摄像头开启失败，请检查摄像头是否可用！')
                })
            }).catch(err =>{
                reject()        
            }) 
            // const constraints = { video: { width: 320, height: 240 } };
            // navigator.mediaDevices
            //     .getUserMedia({
            //         video: { width: 320, height: 240 }
            //     })
            //     .then(function (mediaStream) {
            //         console.log("me", mediaStream)
            //         var video = document.querySelector("video");
            //         console.log("%O", video)
            //         video.style.display = 'block'
            //         // 旧的浏览器可能没有 srcObject
            //         if ("srcObject" in video) {
            //             video.srcObject = mediaStream;
            //         } else {
            //         // 防止在新的浏览器里使用它，应为它已经不再支持了 这种写法在新浏览器中不支持了。
            //             video.src = window.URL.createObjectURL(mediaStream);
            //         }
            //         video.onloadedmetadata = function (e) {
            //             video.play();
            //         };
            //     })
            //     .catch(function (err) {
            //         console.log(err.name + ": " + err.message);
            //     }); // 总是在最后检查错误


            // let _ = this;
            // this.video = document.querySelector("video");

            // if (window.navigator.webkitGetUserMedia) {
            //     window.navigator.webkitGetUserMedia(
            //     {
            //         audio: true,
            //         video: true,
            //     },
            //     (stream) => {
            //         this.MediaStreamTrack =
            //         typeof stream.stop === "function"
            //             ? stream
            //             : stream.getTracks()[1];

            //         //把摄像头捕捉到的数据给视频
            //         // this.video.srcObject = this.MediaStreamTrack;
            //         var video = document.querySelector("video");
            //         if ("srcObject" in video) {
            //             video.srcObject = stream;
            //         } else {
            //         // 防止在新的浏览器里使用它，应为它已经不再支持了 这种写法在新浏览器中不支持了。
            //             video.src = window.URL.createObjectURL(stream);
            //         }
            //         // _.video.src = window.URL.createObjectURL(stream);
            //         video.style.display = 'block'
            //         video.play();

            //         console.log(stream);
            //     },
            //     (err) => {}
            //     );
            // }

        },
        // 拍照
        // photograph() {
        //     let ctx = this.$refs['canvas'].getContext('2d')
        //     // 把当前视频帧内容渲染到canvas上
        //     ctx.drawImage(this.$refs['carmerVideo'], 0, 0, 640, 480)
        //     // 转base64格式、图片格式转换、图片质量压缩
        //     let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg', 0.7)

        //     // 由字节转换为KB 判断大小
        //     let str = imgBase64.replace('data:image/jpeg;base64,', '')
        //     let strLength = str.length
        //     let fileLength = parseInt(strLength - (strLength / 8) * 2)
        //     // 图片尺寸  用于判断
        //     let size = (fileLength / 1024).toFixed(2)
        //     // console.log(size)

        //     // 上传拍照信息  调用接口上传图片 .........

        //     // 保存到本地
        //     this.dialogCamera = false
        //     let ADOM = document.createElement('a')
        //     ADOM.href = this.headImgSrc
        //     ADOM.download = new Date().getTime() + '.jpeg'
        //     ADOM.click()
        // },
        getRandomInteger(min, max){
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
         //每3分钟截图一次
         takeSnapshot3(){
            let that = this;
            // let delaySnapshot = this.getRandomInteger(13,15)  // 随机时间，13-15分钟保存一次
            let delaySnapshot = 10  // 固定时间，10分钟保存一次
            // console.log(delaySnapshot)
            this.timer  = setInterval(()=>{
                this.photograph()
            },delaySnapshot*60*1000)
        },
        // 拍照
        photograph() {
            let ctx = this.canvasElement.getContext('2d')
            // 把当前视频帧内容渲染到canvas上
            ctx.drawImage(this.videoElement, 0, 0, 320, 240)
            // 转base64格式、图片格式转换、图片质量压缩
            this.imgUrl = this.canvasElement.toDataURL('image/jpeg');
            // this.canvasElement.toDataURL();
            
            // console.log('imgBase64',this.imgUrl)
            // 由字节转换为KB 判断大小
            // let str = this.imgUrl.replace('data:image/jpeg;base64,', '')
            // let strLength = str.length
            // let fileLength = parseInt(strLength - (strLength / 8) * 2)
            // // 图片尺寸  用于判断
            // let size = (fileLength / 1024).toFixed(2)

            
            const file = this.imgUrl
            const time = (new Date()).valueOf()
            const name = time + '.jpeg'
            const conversions = this.getFileByBase64(file, name)
            // console.log('conversions',conversions)
            const data = new FormData()
            data.append('file', conversions)
            // var oGrayImg = canvas.toDataURL('image/jpeg')// 截取后的视频封面
            // const file = this.getFileByBase64(this.imgUrl)
            // console.log('imgfilename', file)
            // console.log('data-----',data)
                this.beforeAvatarUpload(conversions)
            // console.log(size)

            // 上传拍照信息  调用接口上传图片 .........

            // 保存到本地
            // this.dialogCamera = false
            // let ADOM = document.createElement('a')
            // ADOM.href = this.imgUrl
            // ADOM.download = new Date().getTime() + '.jpeg'
            // ADOM.click()
        },
       
        beforeAvatarUpload(file) {
            // console.log('00',file)
            const fileName = this.ossSts.filePath + encodeURIComponent(file.name);
            const uploadParams = {
                Bucket: this.ossSts.bucket,
                Key: fileName,
                Body: file,
                ContentType: "application/pdf"
            };

            this.s3.putObject(uploadParams).on('httpUploadProgress', (progress) => {
                // this.uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            }).send((err, data) => {
                if (err) {
                this.$message.error(`文件上传失败: ${err.message}`);
                } else {
                    var params = {
                        Bucket: this.ossSts.bucket,
                        Key: fileName,
                    };
                    this.localVideoUrl = this.s3.getSignedUrl('getObject', params);
                    // console.log('this.localVideoUr',this.localVideoUrl)
                    // let newFile = {
                    //     name: file.name, // 返回的文件名
                    //     url: fileName, // 在 computed 属性中生成签名 URL
                    // };
                    // this.$emit('update-file-list', [...this.fileList, newFile]);
                    // 保存文件
                    this.saveImg(fileName)
                    // this.$message.success(`文件上传成功`);
                }
            });
        },
            //   base64 转 file----------------------begin
        getFileByBase64(data,name) {
            const blobData = this.dataURLtoBlob(data)
            return this.blobToFile(blobData,name)
        },
        // 1,先将base64转换为blob
        dataURLtoBlob(dataurl) {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new Blob([u8arr], {
                type: mime
            })
        },
        // 2,再将blob转换为file
        blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date() // 文件最后的修改日期
            theBlob.name = fileName // 文件名
            return new File([theBlob], fileName, {
                type: theBlob.type,
                lastModified: Date.now()
            })
        },
        // 关闭摄像头
        closeCamera() { 
            if (!this.$refs['carmerVideo'].srcObject) {
                this.dialogCamera = false
                return
            }
            let stream = this.$refs['carmerVideo'].srcObject
            let tracks = stream.getTracks()
            tracks.forEach(track => {
                track.stop()
            })

            this.$refs['carmerVideo'].srcObject = null
            this.$refs.carmerVideo.style.display = 'none';
            this.camraWrapClass.switchClass = 'close'
        },
        // 上一题
        onPre(){
            
            if (this.index == 0) {
                this.index = this.questionList.length - 1;
            }else{
                this.index--;
            }
        },
        // 下一题
        nextQustion() {
            let id = this.questionList[this.index].id;
            let type = this.questionList[this.index].type;
            let anwserIdList= [],content='';
            if(type == 4){
                content = this.selectedOptions[this.index][0]
            }else{
                anwserIdList = this.selectedOptions[this.index]
            }
            // console.log(id)
            // console.log()
            this.saveAnswer(id,anwserIdList,content)
            // this.currentAnswer = "";
            if (this.index == this.questionList.length - 1) {
                // this.index = 0;
                this.$confirm('已经是最后一题了,是否交卷?', '提示', {
                    confirmButtonText: '确定',
                    // cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log('---257875')
                    this.examineEnd()
                })
            }else{
                this.index++;
            }
            // 获取当前题目的id
            
            // let items = document.getElementsByClassName("list-group-item");
            // items.forEach((item) => {
            //     item.style.backgroundColor = "";
            // });
        },
        scrollToQuestion(index){
            this.index = index
        },
        getButtonStyle(index,question) {
            
            let questionStyle = question.type == 2 ? 'success' : question.type == 3 ? 'warning' : question.type == 4 ? 'danger' : 'primary'
            return this.selectedOptions[index].length > 0 ? questionStyle : '';
        },
        // 全屏按钮
        fullBtn() {
            if (this.flag) {
                this.flag = false;
                this.$refs.container.style.height = '100%';
                this.video.style.width = '100%';
                this.video.style.height = '100%';
                this.fullClass.switchClass = 'el-icon-copy-document'
            } else {
                this.flag = true;
                this.$refs.container.style.height = '400px';
                this.video.style.width = '100%';
                this.video.style.height = '400px';
                this.fullClass.switchClass = 'el-icon-full-screen'
            }
        },
        getTime(time) {
            var hours = Math.floor(time % 86400 / 3600);
            var minutes = Math.floor(time % 86400 % 3600 / 60);
            var seconds = Math.floor(time % 60);
            hours = hours >= 10 ? hours : '0' + hours;
            minutes = minutes >= 10 ? minutes : '0' + minutes;
            seconds = seconds >= 10 ? seconds : '0' + seconds;

            var str = '';
            str = hours + ':' + minutes + ':' + seconds;
            return str;
        },
        submitjuanquan(){
            console.log('交卷！！！！！')
           this.examineEnd()
            if(this.examinIndex ==  this.loadingExaminInfo.length - 1){
                this.closeCamera()
            }   
            // this.$confirm('确定要交卷吗？', '提示', {
            //     confirmButtonText: '确定',
            //     // cancelButtonText: '取消',
            //     type: 'warning',
            //     customClass:'new-modal'
            // }).then(() => {
               
            // }).catch(() => {
            //     // this.$message({
            //     //     type: 'info',
            //     //     // message: '已取消'
            //     // });
            // });
            
            // this.$message({
            //     message: '提交成功！',
            //     type: 'success'
            // });
           
              
        },
        // 每5分钟校验一次服务器时间
        fuwuTime5M(){
            let that = this;
           this.fuwuTimer = setInterval(function(){
                // 请求接口，获取服务器时间
                if(this.countDownTimer){
                    clearInterval(this.countDownTimer);
                    // console.log('考试期间倒计时定时器以清除');
                }
                // console.log('09090yyyy')
                that.getFuwuTime()
            }, 5 * 60 * 1000);
        },
        getFuwuTime(){
            let params={
                batchId:this.batchId
            }
            netTool.getTime(params).then(res => {
                if(res.status == 0){
                    this.endTimeAnwswer = res.data.endTime;
                    this.currentTime = res.data.systemTime;
                    
                    let mins = this.minutesUntil(this.currentTime, this.endTimeAnwswer)
                    console.log('mins',mins)
                    this.count = mins*60
                    // console.log('getFuwuTime',this.count)
                    if(this.countDownTimer){
                        clearInterval(this.countDownTimer);
                        // console.log('查询考试时间  考试期间倒计时定时器以清除');
                    }
                    this.countDown()
                //    console.log(res)
                }else{
                    this.$notify.error({
                        title: '错误',
                        message: res.msg
                    });
                }
            })
        },
        // countDown(endTime) {
        //     var m, s;
        //     if (endTime >= 0) {
        //         // 不足10补0
        //         m = Math.floor(endTime / 60) < 10 ? '0' + Math.floor(endTime / 60) : Math.floor(endTime / 60);
        //         s = Math.floor(endTime % 60) < 10 ? '0' + Math.floor(endTime % 60) : Math.floor(endTime % 60);
                
        //         // console.log(m + '分钟' + s + '秒');
        //         this.rocallTime = m + '分钟' + s + '秒'
        //         let _msThis = this;
        //         this.countDownTimer = setTimeout(function() {
        //             // this.$refs.carmerVideo.style.display = 'none';
        //             endTime -= 1;
        //             _msThis.countDown(endTime);
        //         }, 1000);
        //     } else {// 倒计时结束，清理定时器，并交卷

        //         clearInterval(this.countDownTimer); 
        //         // 交卷
        //         this.examineEnd()
        //     }
        // },
        countDown() {
            var m, s;
            if (this.count >= 0) {
                // 不足10补0
                m = Math.floor(this.count / 60) < 10 ? '0' + Math.floor(this.count / 60) : Math.floor(this.count / 60);
                s = Math.floor(this.count % 60) < 10 ? '0' + Math.floor(this.count % 60) : Math.floor(this.count % 60);
                
                // console.log(m + '分钟' + s + '秒');
                this.rocallTime = m + '分钟' + s + '秒'
                let _msThis = this;
                this.countDownTimer = setTimeout(function() {
                    // this.$refs.carmerVideo.style.display = 'none';
                    _msThis.count -= 1;
                    _msThis.countDown(_msThis.count);
                }, 1000);
            } else {// 倒计时结束，清理定时器，并交卷

                clearInterval(this.countDownTimer); 
                // 交卷
                // console.log('交卷')
                console.log('---ttttt')
                this.examineEnd()
            }
        },
        minutesUntil(timeStr1, timeStr2) {
            // 创建两个Date对象
            var time1 = new Date(timeStr1);
            var time2 = new Date(timeStr2);
            // console.log(time1,time2)
            
            // 如果第二个时间点在第一个之前，返回错误或相对于当天的时间
            // if (time2 < time1) {
            //     // 如果想要计算的是相差天数后的时间，可以这样做：
            //     time2.setDate(time2.getDate() + 1);
            //     // 或者返回错误
            //     // throw new Error('Second time is before the first time');
            // }
            
            // 计算时间戳的差值并转换成分钟
            var diff = (time2 - time1) / 1000;  // 转换成秒
            var minutes = diff / 60;           // 转换成分钟
            
            return minutes;
        },
    
        saveImg(url){
            let params={
                'type':2, // 1考试
                "batchId":this.batchId,
	            "oosUrl": url
            }
            netTool.saveLearningImage(params).then(res => {
                if(res.status == 0){
                    this.$message.success(`文件保存成功`);
                }
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.timer) {
            clearInterval(this.timer);
            // console.log('3分钟截图定时器已清除');
        }
        if(this.countDownTimer){
            clearInterval(this.countDownTimer);
            // console.log('考试期间倒计时定时器以清除');
        }
        if(this.fuwuTimer){
            clearInterval(this.fuwuTimer);
            // console.log('5分钟校验一下服务器时间已清除');
        }
        if(this.currentPageTimer11){
            clearTimeout(this.currentPageTimer11);
            // console.log('离开超过5次弹框定时器已清除');
        }
     
        next();
    },
    beforeDestroy(){
        this.closeCamera();
        // this.pauseCountdown();
        if (this.timer) {
            clearInterval(this.timer);
            // console.log('3分钟截图定时器已清除');
        }
        if(this.countDownTimer){
            clearInterval(this.countDownTimer);
            // console.log('考试期间倒计时定时器以清除');
        }
        if(this.fuwuTimer){
            clearInterval(this.fuwuTimer);
            // console.log('5分钟校验一下服务器时间已清除');
        }
        if(this.currentPageTimer11){
            clearTimeout(this.currentPageTimer11);
            // console.log('离开超过5次弹框定时器已清除');
        }
        
    },
    destroyed(){
        this.clearCurrentPage()
        window.onfocus = null;
        window.onblur = null;
    }

}
</script>
<style scoped lang="scss">
.wrap{
    width: 1200px;
    margin:0 auto;
    padding-top: 50px;
    display: flex;
    // min-height: calc(100vh - 200px);
    box-sizing: border-box;
}
.tipsTop{
    color: red;
    font-size: 18px;
    font-weight: bold;
    width: 1200px;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
}
.kaoshi{
    padding: 20px;
    background: #fff;
    flex: 1;
}
.title{
    font-size:16px;
    font-weight:bold;
    color:#333;
    height:40px;
    line-height:40px;
    border-bottom:1px solid #ddd;
    position: relative;
    padding-left:12px;

    &::after{
        position: absolute;
        content: '';
        width:4px;
        height:16px;
        background-color:red;
        top:12px;
        left:0;
        border-radius: 4px;;
    }
}
.tips{
    height: 40px;
    line-height:40px;
    background: rgb(217, 236, 255);
    text-align: center;
    color: #999;
}

.videoTitle{
    height:40px;
    line-height:40px;
    margin-bottom:10px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-size:18px;
    .rate{
        color: #333;
        font-weight: bold;
    }
}
.container {
    position: relative;
    width: 100%;
    min-height: 450px;
    background-color: pink;
}

.container video {
    width: 100%;
    height: 450px;
    background-color: pink;
    object-fit: scale-down;
}
.container  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* left: 3px; */
    bottom: 1px;
    width: 100%;
    height: 20px;
    margin: 0 auto;
    border-radius: 5px;
    background: rgba(0, 0, 0, .18)
}
.control .switch {
    margin: 0 2px;
}
.control .icon {
    cursor: pointer;
    color: #fff;
    font-size: 18px;
}
.control .process {
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background-color: #555;
    overflow: hidden;
    cursor: pointer;

}
.control .process .bar {
    width: 0px;
    height: 100%;
    background-color: #fff;
}
.control .time {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 2px;
}
.control .full {
    cursor: pointer;
    margin-right: 2px;
}
video::-webkit-media-controls,
    video::-moz-media-controls,
    video::-webkit-media-controls-enclosure{
    display:none !important;
}



video::-webkit-media-controls-panel,
video::-webkit-media-controls-panel-container,
video::-webkit-media-controls-start-playback-button {
    display:none !important;
    -webkit-appearance: none;
}
video::-webkit-media-controls-panel,
video::-webkit-media-controls-panel-container,
video::-webkit-media-controls-start-playback-button {
    display:none !important;
    -webkit-appearance: none;
}

.camraWrap{
    position: fixed;
    bottom:0;
    right:0;
    width:320px;
    height:240px;
    z-index:2000;
    background-color: #f1f1f1;
}
.open{
    display: block;
    // display: none;
}
.open1{
    display: inline-block;
}
.close{
    display: none;
}
.kaoshi{
    // margin-top: 20px;
}
.answerWrap{
    margin-top:10px;
    font-size:14px;
    .itemTitle{
        font-size:16px;
        font-weight: 600;
        height:30px;
        line-height: 30px;
        margin-top:20px;
        margin-bottom:5px;
    }
    .curTop{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        height:50px;
        line-height:50px;

        .curItem{
            // color: #409eff;
            font-size: 22px;
            font-weight: bold;
            &.typeStyle1{
                color: #409eff;
            }
            &.typeStyle2{
                color: #67c23a;
            }
            &.typeStyle3{
                color: #e6a23c;
            }
            &.typeStyle4{
                color: #f78989;
            }
        }
        .time{
            color: #409eff;
        }
    }
    .mainCon{
        display: flex;
        
    }
    
}
.answerInfo{
            // width:250px;
            // margin-left: 20px;
            .total-questions{
                margin-bottom: 20px;
                font-size: 16px;;
            }
        }
.bottomLine{
    display: flex;
    font-size: 16px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    span{
        flex: 1;
        text-align: center;
    }
}
/deep/ .el-button--text{
    color: #999;
    font-size: 16px;
}

/deep/ .el-button--primary{
    // font-size: 16px;
}

.tipsItem{
    height:50px;line-height: 50px;font-size: 30px;font-weight: bold;color:red;
}
.examinIndex{
    height:50px;
    line-height:50px;
    font-size:18px;
    b{
        color: red;
        font-size: 20px;
        font-weight: bold;;
    }
}
.new-modal{
    .el-message-box__message{
        font-size: 20px;
    }
}


</style>
<style>
 .new_message{
    font-size: 30px;
    background-color: rgb(241, 222, 222);
    padding: 20px;
    
}
.new_message .el-message__content{
        color:red;
        font-size:30px;  
        font-weight: bold;
    }
   
    .iconNew{

    }
</style>
    