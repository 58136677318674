<template>
    <div class="Question-containers">
        <p class="title" v-show="question.content" v-copy> {{question.content }}</p>
        <el-form  :disabled="isFinished">
          <!-- <el-input @paste.native.capture.prevent='disableCV'
          @copy.native.capture.prevent='disableCV'></el-input> -->
<!-- 多选 -->
        <el-checkbox-group v-if="question.type === 2" v-model="selectedOptions[index]" :disabled="isFullScreen || isFinished">
            <el-checkbox :label="option.id" v-for="(option, optionIndex) in question.subList"  @copy.native.capture.prevent='disableCV'
            :key="option.id">
            <!-- {{ getOptionLabel(optionIndex) }}.  -->
            {{ option.content }}
            </el-checkbox>
        </el-checkbox-group>
<!-- 单选 -->
        <el-radio-group v-else-if="question.type === 1"   v-model="selectedOptions[index][0]" :disabled="isFullScreen || isFinished" v-removeAriaHidden>
            <el-radio :label="option.id" v-for="(option, optionIndex) in question.subList" :key="option.id"  @copy.native.capture.prevent='disableCV'>
           <!-- {{ getOptionLabel(optionIndex) }}.  -->
           {{ option.content }}
            </el-radio>
        </el-radio-group>
<!-- 判断 -->
        <el-radio-group v-else-if="question.type === 3"  v-model="selectedOptions[index][0]" :disabled="isFullScreen || isFinished" v-removeAriaHidden>
          <el-radio :label="option.id" v-for="(option, optionIndex) in question.subList"  @copy.native.capture.prevent='disableCV'
            :key="optionIndex">
            <!-- {{ getOptionLabel(optionIndex) }}.  -->
            {{ option.content }}
            </el-radio>
        </el-radio-group>
<!-- 简答题 -->
        <el-input v-paste v-else-if="question.type === 4" type="textarea"  :rows="4" 
        @paste.native.capture.prevent='disableCV'
           @copy.native.capture.prevent='disableCV'
         v-model="selectedOptions[index][0]" placeholder="请输入内容" :disabled="isFullScreen || isFinished"></el-input>
</el-form>
        
    </div>
  </template>
  <script>
  export default {
    props: {
      // question: Object,
      question: {
        require: true,
        type: Object,
        default: function() {
          return {}
        },
      },
    //   onNext:Function,
      selectedOptions:Array,
      index:Number,
      isFullScreen:Boolean,
      isFinished:Boolean
    },
    data() {
      return {
        ElCheckboxArr:[],
        // selectedOptions:[]
      };
    },
    created(){
        // this.selectedOptions = Array.from({ length: 6}, () => []);
    },
    computed: {
    //   correctAnswer() {
    //     return this.questions[this.index].correct_answer;
    //   },
    
    },
    mounted(){
      
    },
    methods: {
      // selector(answer) {
      //   this.currentAnswer = answer;
      // },
      disableCV(){
          return false
      },
      getOptionLabel(index) {
         return String.fromCharCode(65 + index);
      },
      getRandomList(listArr) {
        let list = [...listArr];
        for (let index = 0; index < list.length; index++) {
          let rIndex = Math.floor(Math.random() * list.length);
          [list[index], list[rIndex]] = [list[rIndex], list[index]];
        }
        // console.log("list------>", list);
        return list;
      },

    },

  };
  </script>
<style scoped lang="scss">
/deep/ .el-checkbox__label{
    display: inline;
    // line-height:30px;
    // padding:10px 0;
}
    /deep/ .el-checkbox__label,.el-radio__label{
        font-size:18px;
        padding-left: 15px;
    }
    /deep/ .el-radio,.el-checkbox{
        display: flex;
        line-height: 25px;
        white-space: normal;
        margin-right: 0;
        margin-bottom: 20px;
        align-items: center;
    }
    /deep/ .el-radio__label{
        font-size:18px;
    }
    /deep/ .el-radio-group{
        display:block;
    }
    /deep/ .el-radio__label{
        padding-left: 10px;
        display: inline-block;
    }
    
    .title{
        border-bottom:1px solid #ccc;
        text-indent: 2rem;
        padding:10px 0;
        line-height:25px;
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
    }
    .customP{
        line-height: 30px;
    }
    .Question-containers{
        min-height:400px;
    }
</style>
  
  